import image1 from "../images/car.jpg";
import image2 from "../images/coming-soon.jpg";
import image3 from "../images/construction.jpg";
import image4 from "../images/mahindra.jpg";
import image6 from "../images/locked.jpg";
import image5 from "../images/cadbury.jpg";

export const dataImages = [
  {
    id: 0,
    image: image1,
  },
  {
    id: 1,
    image: image2,
  },
  {
    id: 2,
    image: image3,
  },
  {
    id: 3,
    image: image4,
  },
  {
    id: 4,
    image: image5,
  },
  {
    id: 5,
    image: image6,
  },
];
