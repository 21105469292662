export const data = [
  {
    id: 0,
    info: "Arya Infoways management team has been involved in consumer IT services since more than a decade and now venturing into specialised IT and Information security services.",
  },
  {
    id: 1,
    info: "Our objective is not to be a next service provider in the crowd but to offer value added services. We aim to limit our product and services to the minimum to have focus and develop expertise.",
  },
  {
    id: 2,
    info: "",
  },
  {
    id: 3,
    info: "",
  },
  {
    id: 4,
    info: "",
  },
];
