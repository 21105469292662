const navlinks = [
  {
    id: 0,
    name: "home",
    path: "#hero",
  },
  {
    id: 1,
    name: "about",
    path: "#about",
  },
  {
    id: 2,
    name: "services",
    path: "#services",
  },
  {
    id: 3,
    name: "contact",
    path: "#contact",
  },
  // {
  //   id: 4,
  //   name: "gallery",
  //   path: "#gallery",
  // },
  // {
  //   id: 4,
  //   name: "terms & condition",
  //   path: "/terms",
  // },
  // {
  //   id: 5,
  //   name: "privacy policy",
  //   path: "/privacy",
  // },
];

export default navlinks;
